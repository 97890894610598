import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { VIDEO_TYPE } from '../../shared/constants/learning-center';

@Component({
  selector: 'app-learning-center-topic-card',
  templateUrl: './learning-center-topic-card.component.html',
  styleUrls: ['./learning-center-topic-card.component.scss'],
  standalone: true,
  imports: [RouterLink, NgClass, TranslateModule],
})
export class LearningCenterTopicCardComponent {
  @Input() thumbnailUrl: string;

  @Input() playIconUrl?: string;

  @Input() contentType: 'video' | 'article';

  @Input() header: string;

  @Input() duration: string;

  @Input() topicLink: string;

  @Input() subTopicLink: string;

  public videoType: string = VIDEO_TYPE;

  constructor(private _logMixpanelEventUseCase: LogMixpanelEventUseCase) {}

  onContentClicked() {
    this._logMixpanelEventUseCase.execute({
      eventName: 'learning_center_content_clicked',
      payload: {
        selectedContentId: this.topicLink,
      },
    });
  }
}
