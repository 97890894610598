import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideRouter } from '@angular/router';
import { LearningCenterTopicCardComponent } from './learning-center-topic-card/learning-center-topic-card.component';
import { LearningCenterTopicContentComponent } from './learning-center-topic-content/learning-center-topic-content.component';
import { LearningCenterTopicSidebarComponent } from './learning-center-topic-sidebar/learning-center-topic-sidebar.component';
import { LearningCenterTopicComponent } from './learning-center-topic/learning-center-topic.component';
import { LearningCenterComponent } from './learning-center.component';

@NgModule({
  imports: [
    CommonModule,
    LearningCenterComponent,
    LearningCenterTopicComponent,
    LearningCenterTopicContentComponent,
    LearningCenterTopicSidebarComponent,
    LearningCenterTopicCardComponent,
  ],
  providers: [
    provideRouter([
      {
        path: '',
        component: LearningCenterComponent,
      },
      {
        path: ':subTopicId',
        component: LearningCenterComponent,
      },
      {
        path: ':subTopicId/:id',
        component: LearningCenterTopicComponent,
      },
    ]),
  ],
})
export class LearningCenterModule {}
