import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SectionModel } from 'src/app/core/domain/learning-center.model';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { ENGLISH_LANGUAGE } from '../../shared/constants/country-language-codes-mapping.constants';
import { VIDEO_TYPE } from '../../shared/constants/learning-center';
import { SiteTranslateService } from '../../shared/services/translate.service';

@Component({
  selector: 'app-learning-center-topic-content',
  templateUrl: './learning-center-topic-content.component.html',
  styleUrls: ['./learning-center-topic-content.component.scss'],
  standalone: true,
  imports: [RouterLink, NgClass, NgIf, NgFor, TranslateModule],
})
export class LearningCenterTopicContentComponent implements OnInit, OnChanges {
  @Input() currentSubTopicId: string;

  @Input() title: string;

  @Input() header: string;

  @Input() readingMinutes: string;

  @Input() videoUrl: string;

  @Input() description: string;

  @Input() duration: string;

  @Input() contentType: string;

  @Input() articleSections: SectionModel[];

  public isLTR = false;

  public assetsPath = '../../../../../assets/img/on-boarding/learning-center/';

  public videoType = VIDEO_TYPE;

  public durationType: string;

  public isFeedbackSubmitted = false;

  public currentContentId: string;

  private _languageChangeSubscription: Subscription;

  constructor(
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private _route: ActivatedRoute,
    private _translateService: TranslateService,
    private _siteTranslateService: SiteTranslateService,
    @Inject(appUrlsConstantsInjectionToken) public appURLs: { [url: string]: string },
  ) {}

  ngOnInit(): void {
    this.isLTR = this._siteTranslateService.getCurrentLanguage() === ENGLISH_LANGUAGE;
    this._languageChangeSubscription = this._translateService.onLangChange.subscribe({
      next: (event: LangChangeEvent) => {
        this.isLTR = event.lang === ENGLISH_LANGUAGE;
      },
    });
    this._route.paramMap.subscribe({
      next: (params) => {
        this.currentContentId = params.get('id')!;
        this.isFeedbackSubmitted = false;
      },
    });
  }

  ngOnChanges(): void {
    this.durationType =
      this.contentType === this.videoType
        ? 'LEARNING_CENTER.VIDEO_DURATION'
        : 'LEARNING_CENTER.ARTICLE_MINUTES';
  }

  feedbackSubmitted(feedback: string): void {
    this.isFeedbackSubmitted = true;
    this._logMixpanelEventUseCase.execute({
      eventName: 'learning_center_content_feedback',
      payload: {
        feedback,
        selectedContentId: this.currentContentId,
      },
    });
  }

  ngOnDestroy(): void {
    this._languageChangeSubscription.unsubscribe();
  }
}
