import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
  standalone: true,
})
export class SearchPipe implements PipeTransform {
  transform(items: any[], searchText: any): any[] {
    if (items && searchText) {
      return items.filter((item) => {
        let accumulatedText = '';
        Object.getOwnPropertyNames(item).forEach((i) => {
          accumulatedText += item[i];
        });
        return accumulatedText.toLowerCase().includes(searchText.toLowerCase());
      });
    }
    return items;
  }
}
