import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ArticleModel, SectionModel, VideoModel } from 'src/app/core/domain/learning-center.model';
import { GetContentBySubTopicIdUseCase } from 'src/app/core/usecases/learning-center/get-content-by-topic-id.usecase';
import { GetItemByContentIdUseCase } from 'src/app/core/usecases/learning-center/get-item-by-content-id.usecase';
import { GetTopicFromSubTopicId } from 'src/app/core/usecases/learning-center/get-topic-from-sub-topic-id.usecase';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { ARTICLE_TYPE, VIDEO_TYPE } from '../../shared/constants/learning-center';
import { LearningCenterTopicContentComponent } from '../learning-center-topic-content/learning-center-topic-content.component';
import { LearningCenterTopicSidebarComponent } from '../learning-center-topic-sidebar/learning-center-topic-sidebar.component';

@Component({
  selector: 'app-learning-center-topic',
  templateUrl: './learning-center-topic.component.html',
  styleUrls: ['./learning-center-topic.component.scss'],
  standalone: true,
  imports: [LearningCenterTopicContentComponent, LearningCenterTopicSidebarComponent],
})
export class LearningCenterTopicComponent implements OnInit {
  public currentContentId: string;

  public currentSubTopicId: string;

  public contentType: string;

  public title: string;

  public header: string;

  public videoUrl: SafeResourceUrl;

  public description: string;

  public duration: string;

  public articleSections: SectionModel[];

  public videoData: VideoModel[];

  public articleData: ArticleModel[];

  public selectedSubTopicName: string;

  constructor(
    private _route: ActivatedRoute,
    private _getItemByContentIdUseCase: GetItemByContentIdUseCase,
    private _sanitizer: DomSanitizer,
    private _getContentBySubTopicIdUseCase: GetContentBySubTopicIdUseCase,
    private _getTopicFromSubTopicId: GetTopicFromSubTopicId,
    private _router: Router,
    @Inject(appUrlsConstantsInjectionToken) public appURLs: { [url: string]: string },
  ) {}

  ngOnInit(): void {
    this.getParams();
  }

  getParams(): void {
    this._route.paramMap.subscribe({
      next: (params) => {
        this.currentContentId = params.get('id')!;
        this.currentSubTopicId = params.get('subTopicId')!;
        this.getContentData();
        this.getSubTopicData();
        this.getSelectedSubTopicName();
      },
    });
  }

  getContentData(): void {
    this._getItemByContentIdUseCase.execute(this.currentContentId).subscribe({
      next: (item) => {
        if (!item) {
          this._router.navigate([this.appURLs.LEARNING_CENTER_URL]);
          return;
        }
        this.title = item.title;
        if (item.hasOwnProperty('videoUrl')) {
          const fetchedItem = item as VideoModel;
          this.header = fetchedItem.header;
          this.videoUrl = this._sanitizer.bypassSecurityTrustResourceUrl(`${fetchedItem.videoUrl}`);
          this.description = fetchedItem.description;
          this.duration = fetchedItem.duration;
          this.contentType = VIDEO_TYPE;
        } else {
          const fetchedItem = item as ArticleModel;
          this.duration = fetchedItem.readingMinutes;
          this.articleSections = fetchedItem.sections;
          this.contentType = ARTICLE_TYPE;
        }
      },
    });
  }

  getSubTopicData(): void {
    this._getContentBySubTopicIdUseCase.execute(this.currentSubTopicId).subscribe({
      next: (content) => {
        this.videoData = content?.videos?.filter((video) => video.id !== this.currentContentId)!;
        this.articleData = content?.articles?.filter(
          (article) => article.id !== this.currentContentId,
        )!;
      },
    });
  }

  getSelectedSubTopicName(): void {
    this._getTopicFromSubTopicId.execute(this.currentSubTopicId).subscribe({
      next: (topic) => {
        if (!topic) {
          this._router.navigate([this.appURLs.LEARNING_CENTER_URL]);
          return;
        }
        this.selectedSubTopicName = topic.subTopics.filter(
          (subTopic) => subTopic.id === this.currentSubTopicId,
        )[0].name;
      },
    });
  }
}
