<div class="topic-content-container">
  <div class="topic-content">
    <a [routerLink]="[appURLs.LEARNING_CENTER_URL, currentSubTopicId]" class="topic-content__home">
      <i
        class="back-arrow"
        [ngClass]="isLTR ? 'icon-dukan-icons-back-en' : 'icon-dukan-icons-back'"
      ></i>
      <p class="caption2--regular content-medium-color">
        {{ 'LEARNING_CENTER.BACK_TO_CONTENT' | translate }}
      </p>
    </a>
    <p
      [ngClass]="contentType === videoType ? 'topic-content__video' : 'topic-content__article'"
      class="caption2--regular content-medium-color"
    >
      {{ contentType | translate }}
    </p>
    <p class="heading2--bold content-main-color">{{ title | translate }}</p>
    <p class="caption2--regular content-medium-color">
      {{ durationType | translate }} {{ duration | translate }}
    </p>
    <ng-container *ngIf="contentType === videoType">
      <iframe class="topic-content__youtube-video" [src]="videoUrl"> </iframe>
      <p class="heading2--bold content-main-color">
        {{ 'LEARNING_CENTER.START_WITH_US' | translate }} {{ header | translate }}
      </p>
      <p class="body2--regular content-main-color">
        {{ description | translate }}
      </p>
    </ng-container>
    <div *ngIf="contentType !== videoType">
      <div class="topic-content__article-section" *ngFor="let section of articleSections">
        <p *ngIf="section.header" class="heading2--bold content-main-color">
          {{ section.header | translate }}
        </p>
        <p class="topic-content__article-section__paragraph body2--regular content-main-color">
          {{ section.text | translate }}
        </p>
        <img *ngIf="section.img" class="topic-content__img" src="{{ section.img }}" />
      </div>
    </div>
  </div>
  <div class="feedback" [ngClass]="{ feedback__submitted: isFeedbackSubmitted }">
    <ng-container *ngIf="!isFeedbackSubmitted; else submitted">
      <p class="body1--medium content-main-color">
        {{ 'LEARNING_CENTER.FEEDBACK' | translate }}
      </p>
      <div>
        <button (click)="feedbackSubmitted('Yes')" class="feedback__button body2--medium">
          {{ 'LEARNING_CENTER.YES' | translate }}
        </button>
        <button (click)="feedbackSubmitted('No')" class="feedback__button body2--medium">
          {{ 'LEARNING_CENTER.NO' | translate }}
        </button>
      </div>
    </ng-container>
    <ng-template #submitted>
      <img src="{{ assetsPath + 'tick.svg' }}" />
      <p class="body1--menium content-main-color">{{ 'LEARNING_CENTER.THANKS' | translate }}</p>
    </ng-template>
  </div>
</div>
