<div class="learning-center">
  <div class="learning-center__side-menu">
    <div class="learning-center__side-menu__header">
      <a
        [routerLink]="[appURLs.PRODUCTS_V2_URL]"
        class="learning-center__side-menu__header__title right"
      >
        <i
          class="back-arrow"
          [ngClass]="isLTR ? 'icon-dukan-icons-back-en' : 'icon-dukan-icons-back'"
        ></i>
        <p class="caption2--regular content-medium-color">
          {{ 'LEARNING_CENTER.BACK_TO_HOMEPAGE' | translate }}
        </p>
      </a>
      <p class="right heading2--bold content-main-color">
        {{ 'LEARNING_CENTER.WELCOME_TO_LEARNING_CENTER' | translate }}
      </p>
      <p *ngIf="selectedLevel" class="right body2--medium content-primary-color">
        {{ 'LEARNING_CENTER.LEVELS.' + selectedLevel.toUpperCase() + '.HEADER' | translate }}
      </p>
    </div>
    <div class="learning-center__side-menu__search">
      <input
        class="learning-center__side-menu__search__input right body1--regular content-main-color"
        placeholder="{{ 'LEARNING_CENTER.SEARCH_PLACEHOLDER' | translate }}"
        [(ngModel)]="searchText"
        (click)="onSearchClicked()"
      />
      <img class="learning-center__side-menu__search__icon" src="{{ assetsPath + 'search.svg' }}" />
    </div>
    <div class="learning-center__side-menu__topics">
      <mat-expansion-panel
        class="topic-expansion-panel"
        *ngFor="let topic of topics | search : searchText"
        hideToggle="true"
        #panel
        [expanded]="selectedTopicId === topic.id"
      >
        <mat-expansion-panel-header
          [ngClass]="panel.expanded ? 'panel-title' : ''"
          (click)="onTopicClicked(topic.id)"
        >
          <mat-panel-title>
            <img src="{{ topic.iconUrl }}" />
            <p class="right body1--medium content-main-color">{{ topic.name | translate }}</p>
          </mat-panel-title>
          <mat-panel-description class="topic-expansion-panel__description">
            <mat-icon class="topic-expansion-panel__description__icon" *ngIf="!panel.expanded"
              >add</mat-icon
            >
            <mat-icon class="topic-expansion-panel__description__icon" *ngIf="panel.expanded"
              >remove</mat-icon
            >
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="panel-content">
          <a
            *ngFor="let subTopic of subTopics[topic.id]"
            class="topic-expansion-panel__subtopic content-medium-color body2--regular"
            [routerLink]="[appURLs.LEARNING_CENTER_URL, subTopic.id]"
            [routerLinkActive]="subTopic.id ? 'active-route' : ''"
            (click)="onSubTopicClicked(subTopic.id)"
          >
            {{ subTopic.name | translate }}
          </a>
        </div>
      </mat-expansion-panel>
    </div>
  </div>
  <div class="learning-center__levels" *ngIf="!selectedLevel">
    <div class="learning-center__levels__intro">
      <img src="{{ assetsPath + 'cap.svg' }}" />
      <div>
        <p class="heading1--bold content-main-color">
          {{ 'LEARNING_CENTER.LEVELS.START_LEARNING' | translate }}
        </p>
        <p class="body1--regular content-main-color">
          {{ 'LEARNING_CENTER.LEVELS.YOUR_WAY' | translate }}
        </p>
      </div>
    </div>
    <div class="right">
      <p class="body1--bold content-main-color">
        {{ 'LEARNING_CENTER.LEVELS.DETERMINE_YOUR_LEVEL' | translate }}
      </p>
      <p class="body2--regular content-medium-color">
        {{ 'LEARNING_CENTER.LEVELS.LEVEL_CLARIFICATION' | translate }}
      </p>
    </div>
    <div class="learning-center__levels__cards">
      <div
        class="learning-center__levels__cards__card"
        *ngFor="let card of expertiseLevelsData"
        (click)="onLevelClicked(card)"
      >
        <img src="{{ card.image }}" />
        <p class="body2--bold content-main-color">{{ card.header | translate }}</p>
        <p class="caption1--regular content-medium-color">{{ card.description | translate }}</p>
      </div>
    </div>
    <img
      class="learning-center__levels__arrow"
      src="{{ assetsPath }}{{ isLTR ? 'arrow-en.svg' : 'arrow-ar.svg' }}"
    />
  </div>
  <div class="learning-center__body" *ngIf="selectedLevel">
    <div class="learning-center__body__header">
      <p class="caption1--regular content-main-color">{{ selectedTopicName | translate }}</p>
      <p class="heading2--bold content-main-color">{{ selectedSubTopicName | translate }}</p>
      <div class="learning-center__body__header__count">
        <p *ngIf="selectedSubTopicVideosNumber" class="caption1--regular content-main-color">
          {{ selectedSubTopicVideosNumber }} {{ 'LEARNING_CENTER.VIDEO' | translate }}
        </p>
        <p
          *ngIf="selectedSubTopicVideosNumber && selectedSubTopicArticlesNumber"
          class="caption1--regular content-main-color"
        >
          -
        </p>
        <p *ngIf="selectedSubTopicArticlesNumber" class="caption1--regular content-main-color">
          {{ selectedSubTopicArticlesNumber }} {{ 'LEARNING_CENTER.ARTICLE' | translate }}
        </p>
      </div>
    </div>
    <div class="learning-center__body__content">
      <app-learning-center-topic-card
        *ngFor="let video of videosContent[selectedSubTopicId]"
        [thumbnailUrl]="video.thumbnailUrl"
        [playIconUrl]="assetsPath + 'play.svg'"
        [contentType]="videoType"
        [header]="video.title"
        [duration]="video.duration"
        [topicLink]="video.id"
        [subTopicLink]="selectedSubTopicId"
      ></app-learning-center-topic-card>
      <app-learning-center-topic-card
        *ngFor="let article of articlesContent[selectedSubTopicId]"
        [thumbnailUrl]="article.thumbnailUrl"
        [contentType]="articleType"
        [header]="article.title"
        [duration]="article.readingMinutes"
        [topicLink]="article.id"
        [subTopicLink]="selectedSubTopicId"
      ></app-learning-center-topic-card>
    </div>
  </div>
</div>
