<div class="learning-center-card">
  <a [routerLink]="['/learning-center', subTopicLink, topicLink]" (click)="onContentClicked()">
    <img
      [ngClass]="
        contentType === videoType
          ? 'learning-center-card__video-img'
          : 'learning-center-card__article-img'
      "
      src="{{ thumbnailUrl }}"
    />
    <img class="learning-center-card__play-icon" src="{{ playIconUrl }}" />
    <div class="learning-center-card__info">
      <p
        [ngClass]="
          contentType === videoType
            ? 'learning-center-card__video'
            : 'learning-center-card__article'
        "
        class="caption2--regular"
      >
        {{ contentType | translate }}
      </p>
      <p class="content-black-color caption1--bold">{{ header | translate }}</p>
      <p class="content-black-color caption2--regular">{{ duration | translate }}</p>
    </div>
  </a>
</div>
