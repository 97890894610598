<div class="topic-sidebar">
  <div class="topic-sidebar__header" *ngIf="videoData?.length || articleData?.length; else empty">
    <p class="body1--medium content-black-color">
      {{ 'LEARNING_CENTER.REST_OF_CONTENT' | translate }}
    </p>
    <p class="heading2--bold content-main-color">{{ selectedSubTopicName | translate }}</p>
    <div class="topic-sidebar__header__count">
      <p *ngIf="videoData?.length" class="caption1--regular content-main-color">
        {{ videoData?.length }} {{ 'LEARNING_CENTER.VIDEO' | translate }}
      </p>
      <p *ngIf="videoData?.length && articleData?.length">-</p>
      <p *ngIf="articleData?.length" class="caption1--regular content-main-color">
        {{ articleData?.length }} {{ 'LEARNING_CENTER.ARTICLE' | translate }}
      </p>
    </div>
  </div>
  <ng-template #empty>
    <div class="topic-sidebar__empty">
      <img src="{{ assetsPath + 'empty-folder.svg' }}" />
      <p class="body2--bold content-medium-color">{{ 'LEARNING_CENTER.EMPTY' | translate }}</p>
      <a [routerLink]="[appURLs.LEARNING_CENTER_URL]">
        <button class="topic-sidebar__empty__button">
          {{ 'LEARNING_CENTER.EXPLORE' | translate }}
        </button>
      </a>
    </div>
  </ng-template>
</div>
<div class="content-cards">
  <app-learning-center-topic-card
    *ngFor="let video of videoData"
    [thumbnailUrl]="video.thumbnailUrl"
    [playIconUrl]="assetsPath + 'play.svg'"
    [contentType]="videoType"
    [header]="video.title"
    [duration]="video.duration"
    [topicLink]="video.id"
    [subTopicLink]="currentSubTopicId"
  ></app-learning-center-topic-card>
  <app-learning-center-topic-card
    *ngFor="let article of articleData"
    [thumbnailUrl]="article.thumbnailUrl"
    [contentType]="articleType"
    [header]="article.title"
    [duration]="article.readingMinutes"
    [topicLink]="article.id"
    [subTopicLink]="currentSubTopicId"
  ></app-learning-center-topic-card>
</div>
