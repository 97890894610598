import { NgFor, NgIf } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ArticleModel, VideoModel } from 'src/app/core/domain/learning-center.model';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { ARTICLE_TYPE, VIDEO_TYPE } from '../../shared/constants/learning-center';
import { LearningCenterTopicCardComponent } from '../learning-center-topic-card/learning-center-topic-card.component';

@Component({
  selector: 'app-learning-center-topic-sidebar',
  templateUrl: './learning-center-topic-sidebar.component.html',
  styleUrls: ['./learning-center-topic-sidebar.component.scss'],
  standalone: true,
  imports: [NgIf, RouterLink, NgFor, LearningCenterTopicCardComponent, TranslateModule],
})
export class LearningCenterTopicSidebarComponent {
  @Input() videoData: VideoModel[];

  @Input() articleData: ArticleModel[];

  @Input() selectedSubTopicName: string;

  @Input() currentSubTopicId: string;

  public videoType: string = VIDEO_TYPE;

  public articleType: string = ARTICLE_TYPE;

  public assetsPath = '../../../../../assets/img/on-boarding/learning-center/';

  constructor(@Inject(appUrlsConstantsInjectionToken) public appURLs: { [url: string]: string }) {}
}
