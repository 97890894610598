import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import {
  ArticleModel,
  SubTopicModel,
  TopicModel,
  VideoModel,
} from 'src/app/core/domain/learning-center.model';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetContentBySubTopicIdUseCase } from 'src/app/core/usecases/learning-center/get-content-by-topic-id.usecase';
import { GetSubTopicsUseCase } from 'src/app/core/usecases/learning-center/get-sub-topics.usecase';
import { GetTopicFromSubTopicId } from 'src/app/core/usecases/learning-center/get-topic-from-sub-topic-id.usecase';
import { GetTopicsUseCase } from 'src/app/core/usecases/learning-center/get-topics.usecase';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { ENGLISH_LANGUAGE } from '../shared/constants/country-language-codes-mapping.constants';
import { ARTICLE_TYPE, VIDEO_TYPE } from '../shared/constants/learning-center';
import { SearchPipe } from '../shared/pipes/search.pipe';
import { SiteTranslateService } from '../shared/services/translate.service';
import { LearningCenterTopicCardComponent } from './learning-center-topic-card/learning-center-topic-card.component';

@Component({
  selector: 'app-learning-center',
  templateUrl: './learning-center.component.html',
  styleUrls: ['./learning-center.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    FormsModule,
    NgFor,
    MatExpansionModule,
    NgClass,
    NgIf,
    MatIconModule,
    RouterLinkActive,
    LearningCenterTopicCardComponent,
    TranslateModule,
    SearchPipe,
  ],
})
export class LearningCenterComponent implements OnInit {
  public assetsPath = '../../../../../assets/img/on-boarding/learning-center/';

  public topics: TopicModel[];

  public subTopics: { [id: string]: SubTopicModel[] } = {};

  public videosContent: { [id: string]: VideoModel[] } = {};

  public articlesContent: { [id: string]: ArticleModel[] } = {};

  public selectedTopicId: string;

  public selectedSubTopicId: string;

  public selectedSubTopicName: string;

  public selectedSubTopicVideosNumber: number;

  public selectedSubTopicArticlesNumber: number;

  public videoType: string = VIDEO_TYPE;

  public articleType: string = ARTICLE_TYPE;

  public selectedCardId: string;

  public selectedTopicName: string;

  public searchText: string;

  public expertiseLevelsData = [
    {
      image: `${this.assetsPath}beginner.svg`,
      header: 'LEARNING_CENTER.LEVELS.BEGINNER.HEADER',
      description: 'LEARNING_CENTER.LEVELS.BEGINNER.DESCRIPTION',
      level: 'beginner',
    },
    {
      image: `${this.assetsPath}intermediate.svg`,
      header: 'LEARNING_CENTER.LEVELS.INTERMEDIATE.HEADER',
      description: 'LEARNING_CENTER.LEVELS.INTERMEDIATE.DESCRIPTION',
      level: 'intermediate',
    },
    {
      image: `${this.assetsPath}expert.svg`,
      header: 'LEARNING_CENTER.LEVELS.EXPERT.HEADER',
      description: 'LEARNING_CENTER.LEVELS.EXPERT.DESCRIPTION',
      level: 'expert',
    },
  ];

  public selectedLevel: string;

  public isLTR = false;

  private _languageChangeSubscription: Subscription;

  constructor(
    private _getTopicsUseCase: GetTopicsUseCase,
    private _getSubTopicsUseCase: GetSubTopicsUseCase,
    private _getContentBySubTopicIdUseCase: GetContentBySubTopicIdUseCase,
    private _getTopicFromSubTopicId: GetTopicFromSubTopicId,
    private _route: ActivatedRoute,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private _router: Router,
    private _translateService: TranslateService,
    private _siteTranslateService: SiteTranslateService,
    @Inject(appUrlsConstantsInjectionToken) public appURLs: { [url: string]: string },
  ) {}

  ngOnInit(): void {
    this.isLTR = this._siteTranslateService.getCurrentLanguage() === ENGLISH_LANGUAGE;
    this._languageChangeSubscription = this._translateService.onLangChange.subscribe({
      next: (event: LangChangeEvent) => {
        this.isLTR = event.lang === ENGLISH_LANGUAGE;
      },
    });
    if (this._route.snapshot.paramMap.get('subTopicId')) {
      this.onSubTopicSelected();
    }
  }

  onLevelClicked(card: any): void {
    this.selectedLevel = card.level;
    this.getTopics();
    this._logMixpanelEventUseCase.execute({
      eventName: 'learning_center_level_clicked',
      payload: {
        level: this.selectedLevel,
      },
    });
  }

  getTopics(): void {
    this._getTopicsUseCase.execute().subscribe({
      next: (topics) => {
        this.topics = topics.filter((topic) => topic.level === this.selectedLevel);
        if (!this._route.snapshot.paramMap.get('subTopicId')) {
          this._router.navigate([this.appURLs.LEARNING_CENTER_URL, this.topics[0].subTopics[0].id]);
        }
      },
    });
  }

  onTopicClicked(topicId: string): void {
    this.selectedTopicId = topicId;
    this.getSubTopics();
    this._logMixpanelEventUseCase.execute({
      eventName: 'learning_center_topic_clicked',
      payload: {
        selectedTopicId: topicId,
      },
    });
  }

  getSubTopics(): void {
    this._getSubTopicsUseCase.execute(this.selectedTopicId).subscribe({
      next: (subTopics) => {
        this.subTopics[this.selectedTopicId] = subTopics;
      },
    });
  }

  onSubTopicSelected(): void {
    this._route.paramMap.subscribe({
      next: (params) => {
        this.selectedSubTopicId = params.get('subTopicId')!;
        this._getContentBySubTopicIdUseCase.execute(this.selectedSubTopicId).subscribe({
          next: (content) => {
            this.selectedSubTopicVideosNumber = content?.videos?.length!;
            this.selectedSubTopicArticlesNumber = content?.articles?.length!;
            this.articlesContent[this.selectedSubTopicId] = content?.articles!;
            this.videosContent[this.selectedSubTopicId] = content?.videos!;
            this.getSelectedSubTopicData();
          },
        });
      },
    });
  }

  getSelectedSubTopicData(): void {
    this._getTopicFromSubTopicId.execute(this.selectedSubTopicId).subscribe({
      next: (topic) => {
        if (!topic) {
          this._router.navigate([this.appURLs.LEARNING_CENTER_URL]);
          return;
        }
        this.selectedLevel = topic.level;
        this.getTopics();
        this.selectedTopicName = topic.name;
        this.selectedTopicId = topic.id;
        this.getSubTopics();
        this.selectedSubTopicName = topic.subTopics.filter(
          (subTopic) => subTopic.id === this.selectedSubTopicId,
        )[0].name;
      },
    });
  }

  onSubTopicClicked(selectedSubTopicId: string): void {
    this._logMixpanelEventUseCase.execute({
      eventName: 'learning_center_subtopic_clicked',
      payload: {
        selectedSubTopicId,
      },
    });
  }

  onSearchClicked(): void {
    this._logMixpanelEventUseCase.execute({
      eventName: 'learning_center_search_clicked',
    });
  }

  ngOnDestroy(): void {
    this._languageChangeSubscription.unsubscribe();
  }
}
