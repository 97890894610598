<div class="learning-center-topic">
  <div class="learning-center-topic__content">
    <app-learning-center-topic-content
      [contentType]="contentType"
      [title]="title"
      [header]="header"
      [videoUrl]="videoUrl"
      [description]="description"
      [duration]="duration"
      [articleSections]="articleSections"
      [currentSubTopicId]="currentSubTopicId"
    ></app-learning-center-topic-content>
  </div>
  <div class="learning-center-topic__side-bar">
    <app-learning-center-topic-sidebar
      [videoData]="videoData"
      [articleData]="articleData"
      [selectedSubTopicName]="selectedSubTopicName"
      [currentSubTopicId]="currentSubTopicId"
    ></app-learning-center-topic-sidebar>
  </div>
</div>
